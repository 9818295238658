<template>
  <div>
    <b-card>
      <b-card-title class="card-title px-1 pt-1 m-0">
        <h1>Centro de Notificaciones</h1>
      </b-card-title>
      <loading :active.sync="isLoading" :full-poge="false"></loading>
      <b-row class="pb-2 px-2">
        <b-col cols="6">
          <button
            class="btn btn-sm button-primary mt-1"
            @click="onNotificationAdd()"
          >
            <feather-icon size="15" icon="PlusIcon" class="mr-50" />
            <span style="font-size: 15px">Crear Notificación</span>
          </button>
        </b-col>
        <b-col cols="6" class="mt-1">
          <filter-bar-component
            ref="search"
            v-on:search="onSearch"
          ></filter-bar-component>
        </b-col>
      </b-row>

      <full-table-component
        ref="fulltable"
        :fields="fields"
        :perPage="10"
        url="/user/store-notification"
      >
        <template slot="buttons-slot" slot-scope="props">
          <div @click="onView(props.rowData)" class="cursor-pointer search">
            <feather-icon size="20" icon="SearchIcon" />
            Ver detalles
          </div>
        </template>
      </full-table-component>
    </b-card>
  </div>
</template>

<script>
// import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FilterBarComponent from "@/layouts/components/FilterBarComponent";
import FullTableComponent from "@/layouts/components/FullTableComponent";
import StoreNotificationAdd from "./StoreNotificationAdd";
import StoreNotificationView from "./StoreNotificationView";

import UtilService from "@/services/UtilService";

export default {
  name: "StoreNotification",
  components: {
    FilterBarComponent,
    FullTableComponent,
  },
  data() {
    let fields = [
      {
        name: "id",
        title: "Id",
        sortField: "id",
      },
      {
        name: "created_at",
        title: "Fecha Creación",
        sortField: "created_at",
        formatter: (value) => {
          return UtilService.date(value);
        },
      },
      {
        name: "title",
        title: "Titulo",
      },
      {
        name: "buttons-slot",
        title: "Acciones",
      },
    ];

    return {
      isLoading: false,
      fields: fields,
    };
  },
  methods: {
    /**
     *
     */
    onSearch(event) {
      this.$refs.fulltable.search(event, {}, true);
    },
    /**
     *
     */
    async onNotificationAdd(item) {
      this.$modal.show(
        StoreNotificationAdd,
        {
          item,
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%; overflow-y: auto",
          adaptive: true,
          width: "70%",
          height: "90%",
        }
      );
    },
    /**
     *
     */
    async onView(item) {
      this.$modal.show(
        StoreNotificationView,
        {
          notification: item,
        },
        {
          styles: "margin-left: 10%; overflow-y: auto",
          adaptive: true,
          width: "70%",
          height: "80%",
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.search:hover {
  color: #f68d1e !important;
}
</style>
