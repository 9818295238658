<template>
  <div class="m-3">
    <div>
      <h3 class="title">DETALLE DE NOTIFICACIÓN</h3>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12">
          <div class="card mx-8 p-2">
            <div class="card-body p-3">
              <loading
                :active.sync="isLoading"
                :can-cancel="false"
                :is-full-page="fullPage"
              ></loading>
              <RowComponent
                :title="'ID:'"
                :content="notification.id.toString()"
              />
              <RowComponent :title="'TÍTULO:'" :content="notification.title" />
              <RowComponent
                :title="'DESCRIPCIÓN:'"
                :content="notification.body"
              />
              <div>
                <p>
                  <b>CREADO: </b>
                  <span>{{ notification.created_at | date_format }}</span>
                </p>
              </div>
              <div>
                <p>
                  <b>ACTUALIZADO: </b>
                  <span>{{ notification.updated_at | date_format }}</span>
                </p>
              </div>
              <div class="d-flex justify-content-end">
                <input
                  type="search"
                  @keyup="getBrands"
                  v-model.trim="search"
                  placeholder="Buscar..."
                  class="form-control my-1 w-25"
                />
              </div>
              <table class="vuetable table table-sm fixed">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th
                      style="text-align: left; padding-left: 150px !important"
                    >
                      Nombre Tienda
                    </th>
                    <th
                      style="text-align: left; padding-left: 150px !important"
                    >
                      Email
                    </th>
                  </tr>
                </thead>
                <tbody class="vuetable-body">
                  <tr v-for="brand in brands" :key="brand.id">
                    <td>{{ brand.id }}</td>
                    <td
                      style="text-align: left; padding-left: 150px !important"
                    >
                      {{ brand.brand }}
                    </td>
                    <td
                      style="text-align: left; padding-left: 150px !important"
                    >
                      {{ brand.email }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="row">
        <div class="col-12">
          <b-button size="sm" class="mr-1 float-right" @click="onClose"
            >Cerrar</b-button
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  name: "UserView",
  props: {
    notification: Object,
  },
  data() {
    return {
      search: "",
      brands: [],
      isLoading: false,
      fullPage: false,
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    getBrands() {
      this.isLoading = true;
      UserService.showNotifications(this.notification.id)
        .then((response) => {
          this.isLoading = false;
          const { data } = response;
          this.brands = data.data.brands;

          if (this.search) {
            this.brands = data.data.brands.filter((brand) => {
              if (
                brand.brand != null &&
                (brand.brand
                  .toLowerCase()
                  .includes(this.search.toLowerCase()) ||
                  brand.email.toLowerCase().includes(this.search.toLowerCase()))
              ) {
                return brand;
              }
            });
          } else {
            this.brands = data.data.brands;
          }
        })
        .catch((thrown) => {
          this.isLoading = false;
          console.error(thrown);
        });
    },
  },
  created() {
    this.getBrands();
  },
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 500px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
