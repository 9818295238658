<template>
  <div>
    <b-card no-body class="p-1" ref="container">
      <b-card-title class="card-title px-1 m-0">
        <h1>Crear Notificación</h1>
      </b-card-title>
      <b-card-body class="mx-1">
        <validation-observer ref="notificationForm" #default="{ invalid }">
          <b-form @submit.prevent="onNotifications">
            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="fullPage"
            ></loading>

            <b-form-group label="Título">
              <validation-provider
                #default="{ errors }"
                name="Titulo"
                rules="required|max:100"
              >
                <b-form-input
                  size="sm"
                  type="text"
                  name="title"
                  v-model="notifications.title"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Descripción">
              <validation-provider
                #default="{ errors }"
                name="Descripción"
                rules="required|max:250"
              >
                <b-form-textarea
                  size="sm"
                  xlength="250"
                  v-model="notifications.body"
                  name="body"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div>
              <b-form-checkbox
                class="mx-1"
                v-model="notifications.type.prePago"
                value="pre-pago"
                name="pre-pago"
                style="display: inline !important"
                switch
              >
                Enviar a todos los pre-pago
              </b-form-checkbox>
              <b-form-checkbox
                class="mx-1"
                v-model="notifications.type.postPago"
                value="post-pago"
                name="post-pago"
                style="display: inline !important"
                switch
              >
                Enviar a todos los post-pago
              </b-form-checkbox>
              <filter-bar-component
                class="mb-1"
                ref="search"
                v-on:search="onSearch"
              ></filter-bar-component>
            </div>

            <full-table-component
              ref="fulltable"
              :fields="fields"
              :perPage="6"
              url="/customers"
              @selectTo="selectTo"
            >
            </full-table-component>

            <b-col cols="12" class="float-right mt-1">
              <footer class>
                <div class="row">
                  <div class="col-12">
                    <b-button
                      size="sm"
                      class="mr-1 float-right"
                      variant="primary"
                      :disabled="invalid"
                      @click="onNotifications"
                      >Guardar</b-button
                    >
                    <b-button
                      size="sm"
                      class="mr-1 float-right"
                      variant="outline-primary"
                      @click="close"
                      >Cancelar</b-button
                    >
                  </div>
                </div>
              </footer>
            </b-col>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import UtilService from "@/services/UtilService";
import AlertService from "@/services/AlertService";
import FullTableComponent from "@/layouts/components/FullTableComponent";
import FilterBarComponent from "@/layouts/components/FilterBarComponent";
import UserService from "@/services/UserService";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  name: "StoreNotificationAdd",
  components: {
    ValidationProvider,
    ValidationObserver,
    FullTableComponent,
    FilterBarComponent,
  },
  props: {
    onClose: Object,
    onSave: Object,
  },
  data() {
    return {
      required,
      isLoading: false,
      fullPage: false,
      notifications: {
        title: null,
        body: null,
        stores: null,
        type: {
          prePago: false,
          postPago: false,
        },
      },
      fields: [
        {
          name: "__checkbox2",
          titleClass: "text-align-center",
          dataClass: "text-align-center",
        },
        {
          name: "type",
          title: "Tipo Tienda",
          sortField: "type",
        },
        {
          name: "brand",
          title: "Empresa",
          sortField: "brand",
        },
        {
          name: "full_name",
          title: "Nombres",
          sortField: "full_name",
        },
        {
          name: "collection",
          title: "Servicio de Colecta",
          sortField: "collection",
          formatter: (value) => {
            return UtilService.serviceCollection(value);
          },
        },
        {
          name: "retirement",
          title: "Servicio de Entrega",
          sortField: "retirement",
          formatter: (value) => {
            return UtilService.serviceRetirement(value);
          },
        },
        {
          name: "location",
          title: "Localidad",
          sortField: "location",
        },
        {
          name: "province",
          title: "Provincia",
          sortField: "province",
        },
      ],
    };
  },
  methods: {
    onNotifications() {
      
      this.$refs.notificationForm.validate().then((valid) => {
        if (valid) {
          this.isLoading = true;
          if (
            this.notifications.stores == null &&
            !this.notifications.type.prePago &&
            !this.notifications.type.postPago
          ) {
            this.isLoading = false;
            AlertService.error(
              "Ocurrio un error",
              "Selecciona una tienda a enviar o tipo de tienda"
            );
          }
        else{
          UserService.storeNotifications(this.notifications)
          .then(
            () => {
              this.isLoading = false;
              this.onSave.handler();
              this.$emit("close");
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  text: `Las notificaciones se enviarion con éxito`,
                  icon: "InfoIcon",
                  variant: "success",
                },
              });
            }
          )
          .catch(() => {
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  text: `Error al tratar de enviar la notificación`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            }
          );
        }
        }
      });
    },
    /**
     *
     */
    onSearch(event) {
      this.$refs.fulltable.search(event, {}, true);
    },
    /**
     *
     */
    selectTo(items) {
      this.notifications.stores = items.map((d) => d.id);
    },
    /**
     *
     */
    close() {
      this.$emit("close");
      this.onClose.handler();
    },
  },
};
</script>